<template>
  <div class="lotteryIndex">
    <Header></Header>
    <div class="indexContent">
      <div class="time_box">
        <div class="times_count">
          <span class="manual_text"></span>
          <!-- <span>剩余：{{ this.getUserInfo.additional.luck_num || 0 }}次</span> -->
          <span
            v-if="
              this.getUserInfo.additional.luck_num ||
                this.getUserInfo.additional.luck_num == 0
            "
            >剩余：{{ this.getUserInfo.additional.luck_num }}次</span
          >
        </div>
        <div class="message_box" v-if="tableData.length > 0">
          <scroll
            ref="seamlessScroll"
            class="scroll_wrap"
            :data="tableData"
            :class-option="defaultOption"
          >
            <div class="roll-item-inner">
              <div
                v-for="item in tableData"
                :key="item.roleId"
                class="item-inner-content"
              >
                <div>
                  <span>{{ item.user.name }}摇中</span
                  ><span class="lottrey_name">{{ item.prize.level.name }}</span>
                  <span>{{ item.prize.name }}</span>
                </div>
              </div>
            </div>
          </scroll>
        </div>
      </div>
      <img
        src="../../assets/images/shake.png"
        alt=""
        class="shake_icon"
        v-if="!showPop"
        @click="getRewards"
        ref="shakeIcon"
      />
      <span class="shake_text" v-if="!showPop" @click="shakeEvent">~~摇~~</span>
      <span class="tips_text" v-if="!showPop" @click="getRewards"
        >摇一摇未弹出奖品时，也可以点一点参与抽奖</span
      >
      <div class="bottom_box" @click="bindRecordList">抽奖结果记录></div>
    </div>
    <van-popup
      v-model="isTip"
      :close-on-click-overlay="false"
      overlay-class="version_pop"
    >
      <div class="mainBody">
        <div class="systemTip">温馨提示</div>
        <div class="pop_text">
          由于ios系统需要手动获取访问动作与方向的权限，为保障抽奖的正常进行，请在访问提示中点击允许。
        </div>
        <van-button type="primary" class="confirm_btn" @click="handleInit"
          >知道了</van-button
        >
      </div>
    </van-popup>
    <!-- <audio src="../../assets/audio/5018.mp3"></audio> -->
    <div class="lottery_modal" v-if="showPop" @click.self="closePop">
      <div class="lottery_pop" v-if="showPop">
        <div class="pop_name">{{ rewardsInfo.level.name }}</div>
        <div class="pop_img">
          <div class="img_box">
            <img :src="rewardsInfo.images" alt="" />
          </div>
        </div>
        <div class="pop_title">{{ rewardsInfo.name }}</div>
        <div
          class="pop_title"
          @click.stop="postShare"
          @click="closePoster(true)"
        >
          点击分享
        </div>
      </div>
    </div>
    <Poster
      v-if="showShareInfo"
      @closePoster="closePoster"
      :shareInfo="shareInfo"
    ></Poster>
  </div>
</template>
<script>
import Vue from "vue";
import { Icon, Popup, Button, Toast } from "vant";
import Header from "./components/header.vue";
import Shake from "shake.js";
import Poster from "./components/poster.vue";
import { mapActions, mapGetters } from "vuex";

import scroll from "../../components/lottery/VueSeamlessScroll.vue";
// import vueSeamless from "vue-seamless-scroll";

Vue.use(Icon);
Vue.use(Popup);
Vue.use(Button);
Vue.use(Toast);
export default {
  components: {
    Header,
    scroll,
    Poster,
  },
  data() {
    return {
      audio: null,
      showShareInfo: false,
      rewardsInfo: { level: {} },
      showPop: false,
      isTip: false,
      tableData: [],
      shareInfo: {},
      flag: true,
      shakeTimes: 0,
      requestTimes: 0,
    };
  },
  created() {
    console.log("页面加载");
    if (location.href.indexOf("#reloaded") == -1) {
      console.log(12345);
      location.href = location.href + "#reloaded";
      location.reload();
    }
    console.log("cookied");
    console.log(this.getCookie("getAction"));
    this.playAudio();
    this.getRewardsList();
    this.initShake();
    // this.getRewards()
    // const isAction = JSON.parse(sessionStorage.getItem("getAction"));
    const isAction = this.getCookie("getAction");
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("like mac os x") > 0) {
      var reg = /os [\d._]*/gi;
      var verinfo = ua.match(reg);
      var version = (verinfo + "")
        .replace(/[^0-9|_.]/gi, "")
        .replace(/_/gi, ".");
      // if (parseFloat(version) >= 13.3) {
      //   localStorage.setItem("getAction", true);
      //   this.isTip = true;
      // }
      if (parseFloat(version) >= 13.3 && !isAction) {
        // sessionStorage.setItem("getAction", 1);
        this.isTip = true;
        setTimeout(() => {
          this.cookieGO("getAction", "already");
        }, 2000);
      }
    }
  },
  mounted() {
    let input = document.getElementsByTagName("input");
    for (const item of input) {
      item.blur();
    }
    console.log("路由名称", this.$route.name === "lotteryIndex");
    // this.$refs.shakeIcon.style.webkitAnimationPlayState = "paused"
    // setTimeout(() => {
    //   this.$refs.shake_icon.style = {
    //     animation: "myfirst 2s infinite ",
    //   };
    // }, 1000);
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
    defaultOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        openTouch: false, // 移动端开启touch滑动
      };
    },
  },
  beforeDestroy() {
    console.log("页面销毁");
    window.removeEventListener("shake", this.removeShake());
  },
  methods: {
    ...mapActions(["fetchUserInfo"]),
    cookieGO(value, key) {
      var today = new Date();
      var expires = new Date();
      expires.setTime(today.getTime() + 1000 * 60 * 60 * 24);

      this.setCookie(value, key, expires);
    },
    setCookie(value, key, expire) {
      window.document.cookie =
        value +
        "=" +
        escape(key) +
        (expire == null ? "" : "; expires=" + expire.toGMTString());
    },
    getCookie(Name) {
      var findcookie = Name + "=";
      if (window.document.cookie.length > 0) {
        // if there are any cookies
        let offset = window.document.cookie.indexOf(findcookie);
        if (offset != -1) {
          // if cookie exists
          offset += findcookie.length; // set index of beginning of value
          let end = window.document.cookie.indexOf(";", offset); // set index of end of cookie value
          if (end == -1) end = window.document.cookie.length;
          return unescape(window.document.cookie.substring(offset, end));
        }
      }
      return null;
    },
    // 关闭摇一摇监听
    removeShake() {
      console.log("清除摇一摇事件");
      this.myShakeEvent = null;
    },
    // 关闭分享海报
    closePoster(value) {
      if (value) {
        this.shareInfo.name = this.rewardsInfo.name;
        this.shareInfo.level = this.rewardsInfo.level.name;
        this.shareInfo.images = this.rewardsInfo.images;
        this.showPop = false;
        // Toast({
        //   message: "海报生成中...",
        //   duration: 2000,
        //   onClose: () => {
        //   },
        // });
      }
      this.showShareInfo = value;
    },
    // 生成海报
    postShare() {},
    // 关闭中奖弹窗
    closePop() {
      this.showPop = false;
    },
    // 音频播放
    playAudio() {
      this.audio = new Audio();
      this.audio.src = "https://devbase.qq.com/storage/5018.mp3";
    },
    // 获取抽奖奖品
    async getRewards() {
      const res = await this.$api.lottery.getRewards({});
      if (res.errorCode == 1016) {
        Toast({
          message: "摇动太频繁,10s之后再进行抽奖",
        });
      } else if (res.errorCode == 1000) {
        this.rewardsInfo = res.data;
        this.audio.play();
        setTimeout(() => {
          this.showPop = true;
          this.fetchUserInfo();
          this.getRewardsList();
        }, 1000);
      } else {
        Toast({
          message: res.errorMessage || "服务器错误",
        });
      }
    },
    initShake() {
      this.myShakeEvent = new Shake({
        threshold: 15, // 摇动阈值
        timeout: 1000, // 事件发生频率，是可选值
      });
      this.myShakeEvent.start();
      window.addEventListener("shake", this.shakeEvent);
    },
    shakeEvent() {
      this.shakeTimes = ++this.shakeTimes;
      // alert(this.shakeTimes);
      // this.showPop = false;
      if (this.$route.name === "lotteryIndex") {
        if (this.flag) {
          Toast({
            message: "进行抽奖啦",
          });
          this.flag = false;
          setTimeout(() => {
            this.flag = true;
            console.log("恢复flag的值");
          }, 3000);
          this.getRewards();
        } else {
          // Toast({
          //   message: "摇动太频繁,10s之后再进行抽奖",
          // });
        }
      }
    },
    handleInit() {
      this.isTip = false;
      this.ios13granted();
    },
    ios13granted() {
      if (typeof DeviceMotionEvent.requestPermission === "function") {
        DeviceMotionEvent.requestPermission()
          .then((permissionState) => {
            if (permissionState === "granted") {
              this.initShake(); //摇一摇
            } else if (permissionState === "denied") {
              // 打开的链接不是https开头
              // alert(
              //   "当前IOS系统拒绝访问动作与方向。请退出微信，重新进入活动页面获取权限。或直接点击手动抽奖参与活动"
              // );
              Toast({
                message:
                  "当前IOS系统拒绝访问动作与方向。请退出微信，重新进入活动页面获取权限。或直接点击手动抽奖参与活动",
              });
            }
          })
          .catch((error) => {
            // alert("请求设备方向或动作访问需要用户手势来提示");
            console.log(error);
            Toast({
              message: "请求设备方向或动作访问需要用户手势来提示",
            });
          });
      } else {
        // 处理常规的非iOS 13+设备
        // alert("处理常规的非iOS 13+设备");
      }
    },
    // 获取奖品轮播列表
    async getRewardsList() {
      const res = await this.$api.lottery.getRewardsList({});
      if (this.$refs.shakeIcon) {
        // this.$refs.shakeIcon.style.animation = "myfirst 2s infinite";
      }
      if (res.errorCode != 1000) {
        Toast({
          message: res.errorMessage,
        });
      } else {
        this.tableData = res.data || "服务器错误";
      }
    },
    // 跳转抽奖记录
    bindRecordList() {
      this.$router.push({
        name: "lotteryList",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.lottery_modal {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  .lottery_pop {
    height: auto;
    position: fixed;
    width: 100%;
    // height: 319px;
    top: 153px;
    padding: 20px 0;
    background: linear-gradient(#434439 0%, #2c2c2c 100%);
    .pop_name {
      font-size: 22px;
      line-height: 24px;
      color: #f8f9fc;
    }
    .pop_img {
      width: 100%;
      // height: 180px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .img_box {
        // width: 180px;
        // height: 180px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        margin: 20px auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          height: 100%;
          display: block;
          width: auto;
          height: auto;
          max-width: 180px;
          max-height: 180px;
        }
      }
      // width: 180px;
      // height: 180px;
      // margin: auto;
      // display: table-cell;
      // vertical-align: middle;
      // text-align: center;
    }
    .pop_title {
      font-size: 18px;
      color: #f8f9fc;
      margin-bottom: 8px;
    }
  }
}
.lotteryIndex {
  box-sizing: border-box;
  height: 100vh;
  /deep/.mainBody {
    padding: 20px;
    width: 220px;
    // width: 80%;
    .systemTip {
      font-size: 18px;
      margin-bottom: 20px;
    }
    .pop_text {
      font-size: 14px;
      color: #0f2128;
      margin-bottom: 20px;
    }
    .confirm_btn {
      width: 114px;
      height: 41px;
      border-radius: 4px;
      background: #e96346;
      border: none;
      font-size: 16px;
    }
  }
  .indexContent {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-top: 44px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .time_box {
      box-sizing: border-box;
      width: 100%;
      // height: 45px;
      position: absolute;
      left: 0;
      top: 44px;
      // display: flex;
      // flex-direction: row;
      // justify-content: space-between;
      // font-size: 14px;
      // color: #838C8F;
      // line-height: 45px;
      // padding: 0 16px;
      .times_count {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px;
        color: #838c8f;
        line-height: 45px;
        padding: 0 16px;
        .manual_text {
          color: #e96346;
        }
      }
      .lottery_modal {
        width: 100vh;
        height: 100vh;
        border: 1px solid red;
        position: absolute;
        left: 0;
        top: 0;
        .lottery_pop {
          border: 1px solid green;
          height: auto;
          position: fixed;
          width: 100%;
          // height: 319px;
          top: 153px;
          padding: 20px 0;
          background: linear-gradient(#434439 0%, #2c2c2c 100%);
          .pop_name {
            font-size: 22px;
            line-height: 24px;
            color: #f8f9fc;
          }
          .pop_img {
            width: 100%;
            // height: 180px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            .img_box {
              // width: 180px;
              // height: 180px;
              display: table-cell;
              vertical-align: middle;
              text-align: center;
              margin: 20px auto;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              img {
                width: 100%;
                height: 100%;
                display: block;
                width: auto;
                height: auto;
                max-width: 180px;
                max-height: 180px;
              }
            }
            // width: 180px;
            // height: 180px;
            // margin: auto;
            // display: table-cell;
            // vertical-align: middle;
            // text-align: center;
          }
          .pop_title {
            font-size: 18px;
            color: #f8f9fc;
            margin-bottom: 8px;
          }
        }
      }
      .message_box {
        width: 100%;
        height: 43px;
        background: rgba(255, 194, 47, 0.13);
        font-size: 14px;
        color: #838c8f;
        text-align: center;
        position: relative;
        overflow: hidden;
        .scroll_wrap {
          width: 100%;
          overflow: hidden;
          padding: 0 20px;
        }
        .roll-item-inner {
          display: flex;
          width: 100%;
          height: 43px;
          .item-inner-content {
            line-height: 100%;
            display: flex;
            // padding-right: 20px;
            flex-shrink: 0;
            white-space: nowrap;
            // background: #eef0f6;
            // border-radius: rem(20);
            margin-right: 20px;
            // height: rem(40);
            height: 43px;
            flex-shrink: 0;

            div {
              height: 43px;
              box-sizing: border-box;
              color: #171f24;
              line-height: normal;
              font-size: 14px;
              color: #838c8f;
              line-height: 43px;
              //   padding: 0 20px;
              .lottrey_name {
                color: #e96346;
                padding: 0 5px;
              }
            }
          }
        }
      }
    }
    .shake_icon {
      width: 123.5px;
      height: 119px;
      margin-bottom: 23px;
      // animation: myfirst 2s infinite;
    }
    @keyframes myfirst {
      50% {
        transform: rotate(90deg);
      }
      // 50% {
      //   transform: rotate(0deg);
      // }
      // 75% {
      //   transform: rotate(-45deg);
      // }
      // 100% {
      //   transform: rotate(-90deg);
      // }
      // 100%{transform: rotate(360deg);}
    }
    .shake_text {
      font-size: 16px;
      color: #e96346;
    }
    .tips_text {
      font-size: 16px;
      color: #e96346;
      margin-top: 20px;
    }
    .bottom_box {
      position: absolute;
      width: 100%;
      text-align: center;
      font-size: 16px;
      color: #838c8f;
      bottom: 44px;
      left: 0;
    }
    //   height: calc(100% - );
  }
}
@media screen and (max-width: 320px) {
  .shake_text {
    font-size: 14px !important;
  }
  .tips_text {
    font-size: 14px !important;
  }
  .bottom_box {
    font-size: 14px !important;
  }
}
</style>
