
/**
   * @desc 深浅合并拷贝
   */
const common = {

  copyObj: function copyObj(obj) {
    //   筛选，如果是引用数据类型则进入if
    if (Array.isArray(obj) || typeof obj === 'object') {
      // 创建变量接受深拷贝数据，如果obj是数组，则变量初始化为数组
      const cloneObj = Array.isArray(obj) ? [] : {};
      //   将变量进行遍历
      Object.keys(obj).forEach((key) => {
        //   如果被拷贝对象为引用数据，则将递归后的数据放入对应的键内
        if (Array.isArray(obj[key]) || typeof obj[key] === 'object') {
          cloneObj[key] = copyObj(obj[key]);
        } else {
          // 否则直接拷贝键值
          cloneObj[key] = obj[key];
        }
      });
      //   然后返回拷贝后的对象
      return cloneObj;
    }
    // 普通数据直接返回
    return obj;
  },
  /**
    * @desc 判断数组是否相等
    * @param {arr1,arr2}
    * @return {Boolean}
    */
  arrayEqual: (arr1, arr2) => {
    if (arr1 === arr2) return true;
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; ++i) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  },
};
export default common;
